function menuHandler() {
    const burgerMenu = document.querySelector('.burger');
    const slideNav = document.querySelector('.menu');

    burgerMenu.addEventListener('click', () => {
        slideNav.classList.toggle('active');
        burgerMenu.classList.toggle('active');
    });

    if (window.innerWidth < 1024) {
        const navLinks = document.querySelectorAll('.menu-item > a');
        const dropdownMenus = document.querySelectorAll('.submenu');

        navLinks.forEach((link) => {
            link.addEventListener('click', () => {
                // remove active className
                dropdownMenus.forEach((menu) => {
                    if (link.parentNode != menu.parentNode) {
                        menu.classList.remove('active');
                    }
                });
                // add active className to current dropdown
                const dropdownMenu = link.nextElementSibling;
                if (dropdownMenu) {
                    dropdownMenu.classList.toggle('active');
                }
            });
        });
    }
}

menuHandler();

function standingsTabsHandler() {
    const standingsLinks = document.querySelectorAll('.standings-link');

    standingsLinks.forEach((link) => {
        link.addEventListener('click', function () {
            let current = document.querySelector('.standings-link.active');
            current.className = current.className.replace('active', '');
            this.classList.add('active');

            linkId = link.getAttribute('data-id');

            let currentContainer = document.querySelector('.standings-tab.active');
            currentContainer.className = currentContainer.className.replace('active', '');
            document.querySelector(`.standings-tab[id="${linkId}"]`)?.classList.add('active');
        });
    });
}

standingsTabsHandler();

const selects = document.querySelectorAll('select.js-select');
if (selects.length) {
    selects.forEach((select) => {
        select.addEventListener('change', () => {
            window.location = select.value;
        });
    });
}

$('.js-slider').slick({
    infinite: true,
    slidesToShow: 5,
    autoplay: true,
    dots: false,
    arrows: false,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
            },
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
            },
        },
    ],
});
